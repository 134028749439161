<template>
  <div class="s-item">
    <div class="s-item-info">
      <div class="s-space-y-2 s-mr-auto">
        <p class="s-text-gray-darker s-font-semibold">發票號碼：{{ invoiceLog.no }}</p>
        <p class="s-text-gray s-text-xs s-whitespace-nowrap">開立日期：{{ moment(invoiceLog.issued_at).format('YYYY-MM-DD') }}</p>
        <p class="s-text-gray s-text-xs s-whitespace-nowrap">登錄時間：{{ moment(invoiceLog.created_at).format('YYYY-MM-DD hh:mm:ss') }}</p>
      </div>
      <div class="s-flex s-flex-col s-items-end">
        
          <span class="s-tag my-1">{{ statusMapping[invoiceLog.status] }}</span>        
          <span class="s-tag my-1 s-tag-outline">{{ typeMapping(invoiceLog) }}</span>
        
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as consts from '@/consts'

export default {
  props: {
    invoiceLog: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusMapping: {
        [consts.INVOICE_LOG_STATUS.INIT]: '等待處理',
        [consts.INVOICE_LOG_STATUS.E_VERIFY]: '發票驗證中',
        [consts.INVOICE_LOG_STATUS.E_VALID]: '發票已處理',
        [consts.INVOICE_LOG_STATUS.E_INVALID]: '發票不存在',
        [consts.INVOICE_LOG_STATUS.E_CANCEL]: '發票已作廢',
        [consts.INVOICE_LOG_STATUS.T_VERIFY]: '發票審核中 ',
        [consts.INVOICE_LOG_STATUS.T_VALID]: '發票已審核',
        [consts.INVOICE_LOG_STATUS.T_INVALID]: '發票不合格',
        [consts.INVOICE_LOG_STATUS.CANCEL]: '發票已取消',
      },
    }
  },
  computed: {
    moment () {
      return moment
    },
  },
  methods: {
    typeMapping(invoiceLog) {
      if (invoiceLog.type === consts.INVOICE_LOG_TYPES.ELECTRONIC) {
        if (invoiceLog.random) {
          return '電子發票'
        } else {
          return '載具發票'
        }
      }
      if (invoiceLog.type === consts.INVOICE_LOG_TYPES.TRADITIONAL) {
        return '傳統發票'
      }
      return '-'
    },
  },
}
</script>

<style scoped>
.s-item {
  display: flex;
  align-items: center;
  background-color: var(--s-white);
}

.s-item-info {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding: 1rem;

  box-shadow: rgb(from var(--s-primary) r g b / .5)  0px 2px 8px 0px;
  border-radius: 5px;
}

.s-tag-outline{
  &:before{
    background-color: var(--s-white);
    border: solid 1px var(--s-black);
  }
}
</style>
