<template>
  <div>
    <div v-if="fetching">
      <Loading />
    </div>
    <template v-else>
      <div class="page">
        <div class="page__title"> {{ moduleConfig.list_page && moduleConfig.list_page.form_title }} </div>
        <div class="page__desc" v-if="moduleConfig.list_page && moduleConfig.list_page.form_desc "> {{ moduleConfig.list_page && moduleConfig.list_page.form_desc }}</div>
        <template v-if="invoiceLogs.length">
          <ul>
            <li class="s-mb-3" v-for="(invoiceLog, i) in invoiceLogs" :key="i">
              <Item :invoiceLog="invoiceLog" :show-image="false" />
            </li>
          </ul>
          <Pagination v-model="page" :total-page="lastPage" />
        </template>
        <div class="s-pt-40 s-text-center" v-else>
          <img class="s-mb-3" src="@/assets/images/liff/empty.svg">
          <p class="s-text-gray">尚未上傳任何發票</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import Item from '@/components/Page/Liff/Einvoice/Item'
import Pagination from '@/components/Page/Liff/Shared/Pagination'
import invoiceApi from '@/apis/liff/v2/invoice'
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getModule: "liffModule/getModule",
    }),
    moduleConfig() {
      return this.getModule('liff_invoice').module_config || {}
    },
  },
  components: {
    Loading, Item, Pagination
  },
  data() {
    return {
      fetching: false,
      invoiceLogs: [],
      page: +this.$route.query.page || 1,
      lastPage: 1,
    }
  },
  watch: {
    page () {
      this.fetchInvoiceLogs()
    },
  },
  mounted() {
    this.fetchInvoiceLogs()
  },
  methods: {
    async fetchInvoiceLogs() {
      this.fetching = true

      try {
        const data = await invoiceApi.getInvoiceLogs({ page: this.page })
        this.invoiceLogs = data.data
        this.lastPage = data.meta.last_page
      } catch (error) {
        console.error(error)
      }

      this.fetching = false
    },
  }
}
</script>
